<template>
    <div class="app-container">
      <h1>Lista osób zapisanych do newslettera</h1>
      <el-button type="primary" style="float: right; margin-bottom:10px" @click="exportUsers()">Export</el-button>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Tytuł">
          <template slot-scope="scope">
            <span>{{ scope.row.email }}</span>
          </template>
        </el-table-column>
      </el-table>
  
      <pagination v-show="total>0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getList" />
    </div>
  </template>
  
  <script>
  import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
  import NewsletterResource from '@/api/newsletter';
  import waves from '@/directive/waves'; // Waves directive
  import permission from '@/directive/permission'; // Permission directive
  
  const newsletterResource = new NewsletterResource();
  
  export default {
    name: 'NewsletterList',
    components: { Pagination },
    directives: { waves, permission },
    data() {
      return {
        total: 1,
        query: {
          page: 1,
          limit: 15,
        },
        list: null,
      };
    },
    created() {
      this.getList();
    },
    methods: {
      handleFilter() {
        this.query.page = 1;
        this.getList();
      },
      async getList() {
        this.loading = true;
        const { data, total } = await newsletterResource.usersList();
        this.list = data;
        this.total = total;
        this.loading = false;
      },
      async exportUsers() {
        try{
            var response = await newsletterResource.export();
            let csv = 'Email\n';
            response.forEach((row) => {
                csv += row.email + '\n';
            });
        
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'newsletter-users.csv';
            anchor.click();
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'users-newsletter.csv'); // Set the desired filename
            // document.body.appendChild(link);
            // link.click();
            // link.remove();
            // console.log(response);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
      },

    },
  };
  </script>
  
  <style lang="scss" scoped>
  .edit-input {
    padding-right: 100px;
  }
  .cancel-btn {
    position: absolute;
    right: 15px;
    top: 10px;
  }
  .dialog-footer {
    text-align: left;
    padding-top: 0;
    margin-left: 150px;
  }
  .app-container {
    flex: 1;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .block {
      float: left;
      min-width: 250px;
    }
    .clear-left {
      clear: left;
    }
  }
  </style>
  